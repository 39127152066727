@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

*{
    font-family: 'Source Sans Pro', sans-serif;
    box-shadow:none !important;
}

b{
    font-weight: 700;
}

img{
    pointer-events: none;
}
